.under-wrap {
  /* width: 100vw; */
  /* height: 100px; */
  /* background: pink; */
  /* padding: 0px 0px 10px 0px; */
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  /* margin-left: 10px; */
  /* margin-top: -5px; */
}

.under-wrap span {
  width: 100%;
  /* max-height: 200px; */
  /* background: green; */
  float: left;
  margin: 0 10px;
  line-height: 1.5;
}
/* .space-btw {
 display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px; 
} */
.img-user-dialog {
  height: 48px;
  width: 48px;
  border-radius: 100%;
  border-style: inset;
  object-fit: cover;
}
.severity-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 15px;
}

/* .inner-avatar {
  position: absolute;
  left: 30px;
  margin-top: 0px;
} */

.center-title {
  text-align: justify;
  margin: 0px 15px 0px 15px;
}

.hashBoxDetail {
  max-width: 90%;
  text-align: left;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  margin-left: 15px;
}

.red-dots {
  font-size: 28px;
  color: red;
}

.scroll-text {  
  overflow-y: auto;
  max-height: 129px;
  padding: 5px;
  text-align: justify;
}

.badge-km {
  font-size: 13px;
  background-color: #ffb639;
  border-radius: 10px;
  padding: 0px 5px 0px 5px;
  color: #000;
  margin-top: 30px;
}

.top-left-dialog {
  position: absolute;
  top: 2px;
  left: 2px;
  /* padding: 5px; */
  border-radius: 100%;
  border-color: #ffffff;
  /* text-align: center; */
  height: 30px;
  /* border-style: ridge; */
  background-color: #ffffff73;
  width: 30px;
}
.mansalva-h6 {
  margin: 0;
  /* font-family: 'Mansalva' !important; */
  font-weight: 900;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: -0.1rem !important;
  /* text-shadow: 0px 1px #fff; */
}

.mansalva-hasbuttons {
  margin: 0;
  font-family: "Mansalva" !important;
  font-weight: 900;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: -0.2rem !important;
  text-shadow: 0px 1px #fff;
}

/* inizio layout elementi dialog detail su mappa */
.header,
.footer {
  /* background-color: pink;
  color: orangered; */
  margin-top: 15px;
}

.column {
  float: left;
  padding: 2px;
}

.columnLeft {
  margin-left: 10px;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.menu {
  width: 132px;
  height: 240px;
  /* background-color: coral; */
  overflow-y: scroll;
  margin-top: 25px;
}

.content {
  width: 72%;
}

.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu li {
  border-radius: 5px;
  padding: 4px;
  margin-bottom: 8px;
  background-color: #008080;
  color: white;
  font-size: 0.789em;
}

.gallery {
  display: grid;
  grid-template-rows: 50% 50%;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  gap: 6px;
  margin-top: 30px;
}

.gallery img {
  /* width: 100%; */
  /* height: 100%; */
  width: 240px;
  height: 180px;
  object-fit: cover;
  border-radius: 10px;
}
